input,
textarea,
p {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter-Regular';
  color: #121212;
  margin: 0;
}

h1 {
  font-size: 30px;
  line-height: 34px;
  font-family: 'Inter-Regular';
  color: #121212;
  margin: 0;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Inter-Regular';
  color: #121212;
  margin: 0;
}

h4 {
  font-size: 16px;
  line-height: 20px;
    font-family: 'Inter-Regular';
  font-weight: 300;
  color: #121212;
  margin: 0;
}

label {
  font-size: 12px;
  line-height: 14.5px;
  font-family: 'Inter-Regular';
  color: #121212;
}
